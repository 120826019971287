/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired     

        // RESPONSIVE NAVIGATION
        jQuery(window).ready(function() {

          $('.nav-resp').hide();

          $('.burger').click(function(){
            $('.nav-resp').slideToggle(500);
          });

        });

        // NAVIGATION DROPDOWNS
        jQuery(window).ready(function() {

          $('.nav-primary .menu-item-has-children .sub-menu').hide();

          // Remove href attribute from top level nav items if they have a sub menu 
          $('.nav-primary .menu-item-has-children > a').removeAttr('href');

          $('.nav-primary .menu-item-has-children > *').on("click", function(){

            // Close any open sub menus
            $(this).parent().siblings().find('.sub-menu.on').slideUp(300);
            
            // Remove .open class from other top level items
            $(this).parent().siblings().removeClass('open');
 
            // Add .open class to clicked item           
            $(this).parent().toggleClass('open');

            // Toggle sub menu of clicked item
            $(this).siblings('.sub-menu').addClass('on').slideToggle(300);
          
          });

        });


        // SMOOTH SCROLL TO ANCHORS
        // jQuery(window).ready(function() {

        //   $('body.home .nav a[href*="#"]').click(function(event) {
        //     event.preventDefault();
        //     $('html,body').animate( { scrollTop:$(this.hash).offset().top } , 1000);
        //     $('#back-to-top').addClass('on');
        //   });

        //   $('body.home #back-to-top').click(function(event) {
        //     event.preventDefault();
        //     $('html,body').animate( { scrollTop:$(this.hash).offset().top } , 1000);
        //     $(this).removeClass('on');
        //   });

        // });


        // BACK TO TOP
        jQuery(window).ready(function() {

          $('#back-to-top').click( function() {
            $("html, body").animate({scrollTop: 0}, 1000);
          });

        });

        // // NEXT PANEL | SCROLL TO ELEMENT
        // jQuery(window).ready(function() {

        //   $('.next-panel').click( function() {
        //     $("html, body").animate({
        //       scrollTop: ($('.layout-panel').offset().top)
        //     }, 1000);
        //   });

        // });





        // FLEXSLIDER
        jQuery(window).ready(function($) {
          
          $('.flexslider').flexslider({
            animation: "slide",
            controlNav: true,
            directionNav: true,
            animationLoop: false,
            slideshow: false,
          });

          // $('#carousel.flexslider').flexslider({
          //   animation: "slide",
          //   controlNav: false,
          //   animationLoop: true,
          //   slideshow: false,
          //   itemWidth: 230,
          //   itemMargin: 0,
          //   asNavFor: '#slider.flexslider'
          // });
        
        });

        // SLICK CAROUSEL
        jQuery(window).ready(function($){
          $('.slick-carousel').slick({
            
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 2,

            autoplay: true,
            autoplaySpeed: 4500,

            dots: true,
            arrows: true,
            speed: 1200,

            lazyLoad: 'ondemand',

            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]

          });
        });

    

        // SLICK SLIDER
        jQuery(window).ready(function(){

          // VARAIBLE WIDTH SLICK
          $('#slick-slider-pro').slick({

            slidesToShow: 1,
            infinite: true,
            arrows: true,
            dots: false,
            speed: 1200,
            autoplay: true,
            autoplaySpeed: 4500,
            centerMode: true,
            variableWidth: true

          });

          // BASIC SLICK
          $('#slick-slider-basic').slick({

            slidesToShow: 1,
            infinite: true,
            arrows: true,
            dots: false

          });

          // BASIC SLICK WITH CONTENT
          $('#slick-slider-content').slick({

            slidesToShow: 1,
            infinite: true,
            arrows: true,
            dots: false,
            speed: 1200,

            fade: true,

            autoplay: true,
            autoplaySpeed: 4500

          });


        });

        

        // // ACCORDION
        // jQuery(window).ready(function(){

        //   $('#accordion').accordion({
        //     collapsible: true,
        //     heightStyle: "content"
        //   });
        //   $('#accordion').accordion().removeClass('ui-accordion-header');

        // });

        // ISOTOPE
        jQuery(window).ready(function($){

          // Init Isotope
          var $grid = $('.isotope-container').isotope({
            // Options
            itemSelector: '.isotope-item'
          });

          // // show all items
          // $grid.isotope({ filter: '*' });


          // // filter items on button click
          // $('.filter-button-group').on( 'click', 'span', function() {
          //   var filterValue = $(this).attr('data-filter');
          //   $grid.isotope({ filter: filterValue });
          // });

          // // change is-checked class on buttons
          // $('.filter-button-group').each( function( i, spanGroup ) {
          //   var $spanGroup = $( spanGroup );
          //   $spanGroup.on( 'click', 'span', function() {
          //     $spanGroup.find('.is-checked').removeClass('is-checked');
          //     $( this ).addClass('is-checked');
          //   });
          // });

          // // Init Isotope - Products Archive
          // var $productgrid = $('ul.products').isotope({
          //   // Options
          //   itemSelector: 'li.product',
          //   percentPosition: true,
          //   columnWidth: 'li.product'
          // });

        });   

        // END
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
